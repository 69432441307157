import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
    >
      <path        
        d="M40 19.485a19.669 19.669 0 01-29.128 17.06L0 40l3.544-10.454A19.28 19.28 0 01.727 19.485a19.637 19.637 0 0139.273 0zM20.364 3.1a16.464 16.464 0 00-16.51 16.385A16.21 16.21 0 007 29.089l-2.064 6.084 6.344-2.016a16.526 16.526 0 0025.593-13.671A16.466 16.466 0 0020.364 3.1zm9.916 20.872c-.121-.2-.442-.319-.923-.557s-2.849-1.395-3.289-1.553-.763-.239-1.084.239-1.243 1.553-1.524 1.872-.561.36-1.043.12a13.175 13.175 0 01-3.87-2.37 14.4 14.4 0 01-2.678-3.307c-.28-.478-.029-.736.211-.974.217-.214.482-.558.722-.836a3.2 3.2 0 00.481-.8.87.87 0 00-.04-.837c-.12-.239-1.084-2.589-1.484-3.545s-.8-.8-1.083-.8-.6-.04-.923-.04a1.776 1.776 0 00-1.284.6 5.341 5.341 0 00-1.684 3.984 9.241 9.241 0 001.965 4.939c.24.318 3.329 5.3 8.222 7.21s4.894 1.274 5.776 1.194S29.6 27.359 30 26.244a3.982 3.982 0 00.28-2.272z"
      ></path>
    </svg>
  );
}

export default Icon;