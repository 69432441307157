import React from 'react'
import styled from '@emotion/styled'
import Button from './Button'
import { Link } from 'gatsby';
import LogoVertical from '../icons/LogoVertical'

const FooterContainer = styled.footer`  
  background: #0B0618;
  color: #fff;
  margin-top: 15rem;
  padding: 3rem;
  position: relative;
  a{
    color: #fff;
    &:hover{
      color: #1BFDBF;
    }
  }
`
const HireUsContainer = styled.div`
  background: #2D2B37;
  border-radius: 1rem;
  margin: -47% auto 0 auto;
  max-height: 30rem;
  padding: 3rem;
  width: 90%;
  span{
    color: #1BFDBF;
  }
  p{
    text-align: center;
  }
`
const FooterSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const FooterSection = styled.div`
  margin: 3rem 0;
  svg{
    width: 60%;
  }
  h4{
    color: #fff;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  ul{
    li{
      line-height: 3.1rem;
    }
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <HireUsContainer>
        <p>
          Para nosotros, los negocios no son sólo construir 
          proyectos increibles,  son tambien <span>crear relaciones 
          duraderas</span>, nos encanta trabajar con personas de 
          valores similares.
        </p>
        <Button
          color="#0B0618"
          bgColor="#FFDA44"
        >
          <a href="/">Contratanos ahora</a>
        </Button>
      </HireUsContainer>
      <FooterSectionContainer>
        <FooterSection>
          <h4>Nosotros</h4>
          <LogoVertical/>
          <p>
            Nuestro núcleo es el código, la minimalista 
            belleza de la sintaxis, semantica y líneas de 
            color en un limpio fondo oscuro.
            Somos exploradores, vanguardistas, 
            domadores de la tecnología.
          </p>
          <p>
            Oficina: +57 301 515 6299
          </p>
          <p>
            Email: hola@corenovo.com
          </p>
        </FooterSection>
        <FooterSection>
          <h4>Servicios</h4>
          <ul>
            <li><Link to="/">Desarrollo Web</Link></li>
            <li><Link to="/">Desarrollo Móvil</Link></li>
            <li><Link to="/">DevOps</Link></li>
          </ul>
        </FooterSection>
      </FooterSectionContainer>
    </FooterContainer>
  );
}
 
export default Footer;