import React from "react";

function CloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      {...props}
    >
      <path        
        d="M42.677 7.323A25 25 0 107.323 42.677 25 25 0 1042.677 7.323zm-2.01 33.344A22.157 22.157 0 019.333 9.333a22.157 22.157 0 1131.334 31.334z"
      ></path>
      <path        
        d="M36.016 13.983a1.416 1.416 0 00-2.01 0l-9.007 9.007-9.007-9.007a1.421 1.421 0 10-2.01 2.01L22.989 25l-9.007 9.007a1.426 1.426 0 001 2.431 1.386 1.386 0 001-.421l9.007-9.007 9.007 9.007a1.43 1.43 0 001 .421 1.369 1.369 0 001-.421 1.416 1.416 0 000-2.01L27.01 25l9.007-9.007a1.416 1.416 0 00-.001-2.01z"
      ></path>
    </svg>
  );
}

export default CloseIcon;
