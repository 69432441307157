import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 40"
      width="6.4rem"
    >
      <g fill="#fff">
        <path
          d="M55.52 39.291l-4.064-4.924-8.48-10.236-5.408-6.543a6.771 6.771 0 00-10.816 0l-5.408 6.543-8.48 10.269-4.384 5.28L0 29.412l4.352-5.28 8.48-10.268 5.92-7.159c7.392-8.94 19.36-8.94 26.72 0l5.92 7.159 8.544 10.268L64 29.056z"
          transform="translate(-82.211 -7) translate(82.211 7)"
        ></path>
        <path
          d="M55.458.359l-4.059 4.963-8.467 10.351-5.4 6.6a6.727 6.727 0 01-10.8 0l-5.4-6.6L12.85 5.322 8.503 0 .032 10.351l4.347 5.322 8.471 10.351 5.913 7.216c7.384 9.012 19.339 9.012 26.69 0l5.913-7.216 8.5-10.351 4.066-4.963z"
          opacity="0.7"
          transform="translate(-82.211 -7) translate(82.211 7)"
        ></path>
      </g>
    </svg>
  );
}

export default Logo;