import React from "react";

function Menu() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 26.25"
      width="3rem"
    >
      <path
        fill="#fff"
        d="M1.071 4.821h27.858A1.071 1.071 0 0030 3.75V1.071A1.071 1.071 0 0028.929 0H1.071A1.071 1.071 0 000 1.071V3.75a1.071 1.071 0 001.071 1.071zm0 10.714h27.858A1.071 1.071 0 0030 14.464v-2.678a1.071 1.071 0 00-1.071-1.071H1.071A1.071 1.071 0 000 11.786v2.679a1.071 1.071 0 001.071 1.071zm0 10.714h27.858A1.071 1.071 0 0030 25.179V22.5a1.071 1.071 0 00-1.071-1.071H1.071A1.071 1.071 0 000 22.5v2.679a1.071 1.071 0 001.071 1.071z"
      ></path>
    </svg>
  );
}

export default Menu;
