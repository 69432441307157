import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby';
import LogoIcon from '../icons/Logo'
import MenuIcon from '../icons/Menu'
import CloseIcon from '../icons/Close'
import WhatsAppIcon from '../icons/WhatsApp'

const MenuContainer = styled.nav`
  background: #0B0618;
  box-shadow: 0 .3rem .6rem rgba(13, 52, 79, .2);
  display: flex;
  justify-content: space-between;
  max-height: 8rem;
  padding: 2rem 3rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  svg{
    cursor: pointer;
  }
`
const MenuItemsContainer = styled.div`
  align-items: center;
  background: rgba(11, 6, 24, .9);
  bottom: 0;
  display: flex;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  &.menu-open-enter {
    opacity: 0;
  }
  &.menu-open-enter-active{
    opacity: 1;
    transition: opacity 200ms;
  }
  &.menu-open-exit {
    opacity: 1;
  }
  &.menu-open-exit-active{
    opacity: 0;
    transition: opacity 200ms;
  }  
  ul{
    padding: 0 3rem;
    width: 100%;
    li{
      font-family: 'Nunito', sans-serif;
      font-size: 4rem;
      line-height: 5.2rem;  
      margin-bottom: 1rem;
      text-transform: uppercase;
      a{
        color: #1BFDBF;
        display: block;
        transition: all .5s ease-in-out;
        &:hover{
          color: #FFDA44;
        }
      }
    }
  }`
const IconContainer = styled.div`
  align-items: center;
  display: flex;
  svg{
    fill: #1bfdbf;
    transition: all .5s ease-in-out;
    &:hover{
      fill: #FFDA44;
    }
  }
`
const ContactContainer = styled.div`
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 6rem;
  position: fixed;
  width: 100%;
  svg{
    width: 4rem;
  }
`


const Header = () => {
  const [ openMenu, setOpenMenu ] = useState(false)
  return (
    <MenuContainer>
      <Link to="/"><LogoIcon /></Link>      
      <IconContainer onClick={() => setOpenMenu(true)} >
        <MenuIcon />
      </IconContainer>
      <CSSTransition
          classNames='menu-open'
          in={openMenu}
          timeout={200}
          unmountOnExit         
        >
          <MenuItemsContainer>
            <IconContainer
              onClick={() => setOpenMenu(false)}
            >
              <CloseIcon css={css`
                position: fixed;
                right: 6rem;
                top: 6rem;
                width: 5rem;
              `} />
            </IconContainer>            
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/#services">Servicios</Link></li>
              <li><Link to="/">Blog</Link></li>
            </ul>
            <ContactContainer>
              <IconContainer>
                <WhatsAppIcon/>
              </IconContainer>
            </ContactContainer>     
          </MenuItemsContainer>  
        </CSSTransition>
    </MenuContainer>
  );
}
 
export default Header;