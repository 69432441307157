import React from 'react'
import styled from '@emotion/styled'

const Element = styled.button`
  background: ${ props => props.bgColor ? props.bgColor : '#5E20E4'};
  border-radius: .5rem;
  border: 0;  
  font-family: 'Nunito', sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1.8rem 2.5rem;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;
  a {
    color: ${ props => props.color ? props.color : '#fff'};
  }
  &:hover{
    background: ${ props => props.bgHoverColor ? props.bgHoverColor : '#1BFDBF'};
    cursor: pointer;
    a {
      color: ${ props => props.hoverColor ? props.hoverColor : '#0B0618'};
    }
  }

`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`  

const Button = (props) => {
  return (
    <ButtonContainer>
      <Element {...props}>
        { props.children }
      </Element>
    </ButtonContainer>
  )
}
 
export default Button;