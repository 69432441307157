import React from 'react'
import Helmet from 'react-helmet'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import Header from './Header'
import Footer from './Footer'

const Main = styled.main`
  margin-top: 8rem;
`

const Layout = ({ title, desc, children }) => {
  return (
    <>
      <Global 
        styles={css`
          html {
            box-sizing: border-box;
            font-size: 62.5%;
          }
          *, *:before, *:after{
            box-sizing: inherit
          }
          *::selection {
            background: #1BFDBF;
            color: #2D2B37;
          }
          body {
            color: #76797B;
            font-family: 'Rubik', sans-serif;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.4rem;
          }
          h1, 
          h2, 
          h3,
          h4{
            color: #3B3663;
            font-family: 'Nunito', sans-serif;
            font-weight: 700;
            margin-bottom: 1rem;
            margin-top: 0;
          }
          h1{
            font-size: 4rem;
            line-height: 4.8rem;
          }
          h2{            
            font-size: 3rem;
            line-height: 3.8rem;
          }
          h3{
            font-size: 2rem;
            line-height: 2.4rem;
          }
          h4{
            font-size: 1.8rem;
            line-height: 2.2rem;
            text-transform: uppercase;
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }
          a {
            text-decoration: none;
          }
          img {
            max-width: 100%;
          }
          strong {
            font-weight: 700;
          }        
        `}
      />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" integrity="sha256-l85OmPOjvil/SOvVt3HnSSjzF1TUMyT9eV0c2BzEGzU=" crossorigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&family=Rubik&display=swap" rel="stylesheet"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
      </Helmet>
      <Header />
      <Main>
        { children }
      </Main>
      <Footer />
    </>
  );
}
 
export default Layout;